<template>
  <div>
    <div class="container">
      <h1 class="text-uppercase">
        <span v-if="language === 'us'">Privacy Policy</span>
        <span v-if="language === 'mx'">Política de Privacidad</span>
      </h1>
    </div>
    <div class="privacy-head-content pb-20">
      <div class="container">
        <template v-if="language === 'us'">
          <div>
            <p>
              <strong>Effective as of January 1, 2020<br />&zwj;</strong>This
              privacy policy governs your use of the software application TREBEL
              MUSIC (“Application”) for mobile devices that was created by
              M&amp;M Media, Inc. TREBEL MUSIC is a mobile music application
              that allows users to search, preview, and download music for
              consumption on the mobile device.f a div block.<br /><span></span>
            </p>
            <h3>1. User Provided Information</h3>
            <p>
              The Application obtains the information you provide when you
              download and register the Application. Registration with us is
              mandatory in order to be able to use the basic features of the
              Application. When you register with us and use the Application,
              you generally provide (a) your name, email address, age, user
              name, password and other registration information; (b)
              transaction-related information, such as when you make purchases,
              respond to any offers, or download or use applications or content
              from us; (c) information you provide us when you contact us for
              help; and; (d) information you enter into our system when using
              the Application, such as contact information and other information
              that may relate to your tastes and preferences about digital
              content, your social network friends, age, gender, and other
              personal information. We may use the information you provided us
              to contact you from time to time to provide you with important
              information, required notices, and marketing promotions.
            </p>
            <h3>2. Automatically Collected Information</h3>
            <p>
              In addition, the Application may collect certain information
              automatically, including, but not limited to, the type of mobile
              device you use, your mobile device's unique device ID, the IP
              address of your mobile device, your mobile operating system, the
              type of mobile Internet browsers you use, and information about
              the way you use the Application. Application has a “filters”
              feature which uses depth and facial mapping tools provided by
              augmented reality programming interface. Data collected from depth
              and facial mapping tools are not used for advertising or other
              use-based data mining purposes, and are not permanently stored or
              shared with third parties.
            </p>
            <h3>
              3. Does the Application collect precise real time location
              information of the device?
            </h3>
            <p>
              When you visit the mobile application, we may use GPS technology
              (or other similar technology) to determine your current location
              in order todetermine the city you are located within and display a
              location map with relevant advertisements and places to check-in
              to. We will not share your current location with other users or
              partners, for purposes other than delivering relevant advertising.
              If you do not want us to use your location for the purposes set
              forth above, you should turn off the location services for the
              mobile application located in your account settings or in your
              mobile phone settings and/or within the mobile application.
            </p>
            <h3>
              4. Do third parties see and/or have access to information obtained
              by the Application?
            </h3>
            <p>
              Yes. We will share your information with third parties only in the
              ways that are described in this privacy statement. We may disclose
              User Provided and Automatically Collected Information:
            </p>
            <ul>
              <li>
                as required by law, such as to comply with a subpoena, or
                similar legal process;<br />
              </li>
              <li>
                when we believe in good faith that disclosure is necessary to
                protect our rights, protect your safety or the safety of others,
                investigate fraud, or respond to a government request;
              </li>
              <li>
                with our trusted services providers who work on our behalf, do
                not have an independent use of the information we disclose to
                them, and have agreed to adhere to the rules set forth in this
                privacy statement.
              </li>
              <li>
                if M&amp;M Media is involved in a merger, acquisition, or sale
                of all or a portion of its assets, you will be notified via
                email and/or a prominent notice on our Web site of any change in
                ownership or uses of this information, as well as any choices
                you may have regarding this information.
              </li>
              <li>
                to advertisers and third party advertising networks and
                analytics companies as described in the section below
              </li>
            </ul>
            <h3>5. Automatic Data Collection and Advertising</h3>
            <p>
              We may work with analytics companies to help us understand how the
              Application is being used, such as the frequency and duration of
              usage. We work with advertisers and third party advertising
              networks, who need to know how you interact with advertising
              provided in the Application which helps us keep the cost of the
              Application free or low. Advertisers and advertising networks use
              some of the information collected by theApplication, including,
              but not limited to, the unique identification ID of your mobile
              device and your mobile telephone number. To protect the anonymity
              of this information, we use an encryption technology to help
              ensure that these third parties can’t identify you personally.
              These third parties may also obtain anonymous information about
              other applications you’ve downloaded to your mobile device, the
              mobile websites you visit, your non-precise location information
              (e.g., your zip code), and other non- precise location information
              in order to help analyze and serve anonymous targeted advertising
              on the Application and elsewhere. We may also share encrypted
              versions of information you have provided in order to enable our
              partners to append other available information about you for
              analysis or advertising related use. We use third-party
              advertising companies to serve ads when you visit our Web site or
              user TREBEL MUSIC app. These companies may use aggregated
              information (not including your name, address, email address or
              telephone number) about your visits to this and other Web sites in
              order to provide advertisements about goods and services of
              interest to you. If you would like more information about this
              practice and to know your choices about not having this
              information used by these companies, please see:
              http://www.networkadvertising.org/managing/opt_out.asp
            </p>
            <h3>6. What are my opt-out rights?</h3>
            <p>
              You can stop all collection of information by the Application
              easily by uninstalling the Application. You may use the standard
              uninstall processes as may be available as part of your mobile
              device or via the mobile application marketplace or network.
            </p>
            <h3>7. Data Retention Policy, Managing Your Information</h3>
            <p>
              You can stop all collection of information byWe will retain User
              Provided data for as long as you use the Application and for a
              reasonable time thereafter. We will retain Automatically Collected
              information for up to 24 months and thereafter may store it in
              aggregate. If you’d like us to delete User Provided Data that you
              have provided via the Application, please contact us at
              privacy@trebelmusic.com and we will respond in a reasonable time
              frame. Please note that some or all of the User Provided Data may
              be required in order for the Application to function properly. the
              Application easily by uninstalling the Application. You may use
              the standard uninstall processes as may be available as part of
              your mobile device or via the mobile application marketplace or
              network.
            </p>
            <h3>
              8. Children
            </h3>
            <p>
              You can stop all collection of information byWe will retain User
              Provided data for as long as you use the Application and for a
              reasonable time thereafter. We will retain Automatically Collected
              information for up to 24 months and thereafter may store it in
              aggregate. If you’d like us to delete User Provided Data that you
              have provided via the Application, please contact us at
              privacy@trebelmusic.com and we will respond in a reasonable time
              frame. Please note that some or all of the User Provided Data may
              be required in order for the Application to function properly. the
              Application easily by uninstalling the Application. You may use
              the standard uninstall processes as may be available as part of
              your mobile device or via the mobile application marketplace or
              network.
            </p>
            <h3>9. Security</h3>
            <p>
              We are concerned about safeguarding the confidentiality of your
              information. We provide electronic and procedural safeguards to
              protect information we process and maintain. For example, we limit
              access to this information to authorized employees and contractors
              who need to know that information in order to operate, develop or
              improve our Application. Please be aware that, although we
              endeavor to provide reasonable security for information we process
              and maintain, no security system can prevent all potential
              security breaches.
            </p>
            <h3>10. Changes</h3>
            <p>
              This Privacy Policy may be updated from time to time for any
              reason. We will notify you of any changes to our Privacy Policy by
              posting the new Privacy Policy here. You are advised to consult
              this Privacy Policy regularly for any changes, as continued use is
              deemed approval of all changes.
            </p>
            <h3>
              11. Your Consent
            </h3>
            <p>
              By using the Application, you are consenting to our processing of
              your information as set forth in this Privacy Policy now and as
              amended by us. "Processing,” means using cookies on a computer/
              hand held device or using or touching information in any way,
              including, but not limited to collecting, storing, deleting,
              using, combining and disclosing information.
            </p>
            <h3>12. Contact Us</h3>
            <p>
              If you have any questions regarding privacy while using the
              Application, or have questions about our practices, please contact
              us via email at
              <a href="mailto:privacy@trebelmusic.com"
                >privacy@trebelmusic.com.</a
              >
            </p>
            <h3>13. Additional California Privacy Disclosures</h3>
            <p>
              <em>Effective as of January 1, 2020</em>If you are a California
              resident, the processing of certain personal data about you may be
              subject to the California Consumer Privacy Act (“CCPA”) and other
              applicable California state privacy laws. Beginning January 1,
              2020, the CCPA gives you certain rights with respect to the
              processing of your personal data (known as “personal information”,
              as described in under the CCPA). This supplement provides
              additional privacy disclosures and informs you of your additional
              rights as a California resident, and should be read in conjunction
              with the Privacy Policy above.
            </p>
            <h3>Personal Information Collected and Processed</h3>
            <p>
              The Privacy Policy above sets forth the categories of personal
              information that TREBEL collects and processes about you, a
              description of each category, and the sources from which we obtain
              each category.
            </p>
            <h3>Requests to Exercise Your Rights</h3>
            <p>
              Right to know request. Under the CCPA, you have a right to request
              information about our collection, use, and disclosure of your
              personal information over the prior 12 months, and ask that we
              provide you with the following information:
            </p>
            <ul>
              <li>
                Categories of and specific pieces of personal information we
                have collected about you.<br />
              </li>
              <li>
                Categories of sources from which we collect personal
                information.
              </li>
              <li>
                Purposes for collecting, using, or selling personal information.
              </li>
              <li>
                Categories of third parties with which we share personal
                information.
              </li>
              <li>
                Categories of personal information disclosed about you for a
                business purpose.
              </li>
              <li>
                If applicable, categories of personal information sold about you
                and the categories of third parties to which the personal
                information was sold, by category or categories of personal
                information for each third party to which the personal
                information was sold.
              </li>
            </ul>
            <p>
              To make a verifiable request for information about the personal
              information we have collected about you, please email us at
              <a href="mailto:privacy@trebelmusic.com"
                >privacy@trebelmusic.com.</a
              >
              from the same email address you used to sign-up for TREBEL
              Music.<br />&zwj;<br />&zwj;<strong
                >Right to delete request.</strong
              >
              You also have a right to request that we delete personal
              information, subject to certain exceptions. You may exercise your
              right to delete by emailing us at
              <a href="mailto:privacy@trebelmusic.com"
                >privacy@trebelmusic.com.</a
              >
              from the same email address you used to sign-up for TREBEL Music
              with a Subject line of “I want to permanently delete my data.”<br />&zwj;<br />&zwj;<strong
                >Household requests.</strong
              >
              We currently do not collect household data. If all the members of
              a household makes a Right to Know or Right to Delete request, we
              will respond as if the requests are individual requests.<br />&zwj;<br />&zwj;<strong
                >Requests, generally.</strong
              >
              Please note, if you do not have a TREBEL account that was created
              with a valid email address we will not have enough information
              about you to verify your Right to Know and Right to Delete
              requests. You may make a verifiable consumer request related to
              your personal information twice per 12-month period. We will not
              discriminate against you for exercising any of your rights under
              the CCPA.<br />&zwj;<br />&zwj;<strong
                >Requests made through agents.</strong
              >
              You may designate, in writing or through a power of attorney, an
              authorized agent to make requests on your behalf to exercise your
              rights. Before accepting such a request from an agent, we will
              require the agent to provide proof you have authorized it to act
              on your behalf, and we may need you to verify your identity
              directly with us.<br />
            </p>
            <h3>Disclosures of Personal Information for a Business Purpose</h3>
            <p>
              In the preceding 12 months, TREBEL has disclosed certain data from
              the following categories of personal information for one or more
              business purposes:
            </p>
            <ul>
              <li>User Data</li>
              <li>Usage Data</li>
              <li>Payment and Purchase Data</li>
              <li>Contests, Surveys and Sweepstakes Data</li>
            </ul>
            <h3>Sale of Personal Information</h3>
            <p>
              Under the CCPA, a “sale” means providing to a third party personal
              information for valuable consideration. It does not necessarily
              mean money was exchanged for the transfer of personal information.
              We have taken substantial steps to identify whether any of our
              data sharing arrangements would constitute a “sale” under the
              CCPA. Due to the complexities and ambiguities in the CCPA, we will
              continue to evaluate some of our third party relationships as we
              wait for final implementing regulations and guidance. For example,
              it is currently unclear whether the use of certain types of
              advertising partners would be considered a sale under CCPA. We
              provide Usage Data to advertising partners, which enables us to
              provide you with interest-based advertising. If you prefer not to
              receive interest-based advertising, please opt out by going to
              your device Settings - Privacy page and using the Limit Ad
              Tracking opt out toggle. We will continue to update our business
              practices as regulatory guidance becomes available and provides
              clarity on what constitutes a sale transaction, particularly in
              the advertising ecosystem.
            </p>
            <h3>Contact Us</h3>
            <p>
              You may contact us by emailing us at
              <a href="mailto:privacy@trebelmusic.com"
                >privacy@trebelmusic.com.</a
              >
            </p>
          </div>
        </template>
        <template v-if="language === 'mx'">
          <div>
            <p>
              <strong
                >Fecha que entra en efecto: 1 de junio de 2021<br /></strong
              >Esta política de privacidad gobierna el uso de la aplicación de
              software TREBEL MUSIC (“La Aplicación”) para dispositivos móviles
              que fue creada por M&M Media, Inc. TREBEL MUSIC es una aplicación
              móvil para música que permite a los usuarios buscar, escuchar
              previas, y descargar música para consumo en el dispositivo móvil.
            </p>
            <p>
              M&M MEDIA, INC. (en lo sucesivo TREBEL), señalando como domicilio
              para efectos de la presente Política, así como para recibir y oír
              notificaciones relativas a la misma el ubicado en 700 Canal St.
              Stamford CT, 06902 USA es el responsable del uso, tratamiento y
              protección de sus datos personales, por lo que en cumplimiento a
              la Ley Federal de Protección de Datos Personales en Posesión de
              Particulares (en adelante la Ley Federal) le informamos lo
              siguiente:
            </p>
            <h3>1. ¿Para qué fines utilizaremos sus datos personales?</h3>
            <p>
              Los datos personales que recabamos de usted, serán utilizados para
              las siguientes finalidades principales, y que son necesarias para
              el correcto funcionamiento de “La Aplicación” y los servicios que
              ésta ofrece:
            </p>
            <ul>
              <li>
                Para verificar y confirmar su identidad, su nombre de usuario y
                contraseña dentro de "La Aplicación".
              </li>
              <li>
                Para confirmar su mayoría de edad y capacidad legal para aceptar
                "La Política de Privacidad".
              </li>
              <li>
                Para mostrarle publicidad y anuncios personalizados dentro de
                "La Aplicación".
              </li>
              <li>
                Para enviarle información respecto de "La Aplicación", así como
                las modificaciones a "La Política de Privacidad".
              </li>
              <li>
                Para hacerle llegar el boletín de noticias relevantes del
                TREBEL.
              </li>
              <li>
                Para generar datos estadísticos que son usados para entender su
                interacción con la publicidad presentada en "La Aplicación".
              </li>
            </ul>
            <p>
              De manera adicional, utilizaremos su información personal para las
              siguientes finalidades que no son necesarias para el
              funcionamiento de “La Aplicación” (“Finalidades Adicionales”),
              pero que nos permiten y facilitan brindarle una mejor atención,
              así como ofrecerle productos y servicios de nuestros socios
              comerciales:
            </p>
            <ul>
              <li>
                Para incluir al Usuario en promociones, concursos, rifas y/o
                competencias propias o de terceros que tengan como objeto
                seleccionarlo para ser parte de programas de televisión, radio,
                Internet o algún otro medio de comunicación.
              </li>
              <li>
                Para contactarlo vía telefónica, correo electrónico, mensajes de
                texto (SMS) o cualquier medio físico o electrónico para
                informarle sobre cualquier beneficio al que hubiera sido
                acreedor en virtud de los servicios prestados por “La
                Aplicación”, por nuestra parte, por terceros o por nuestros
                socios comerciales.
              </li>
            </ul>
            <p>
              En caso de que no desee que sus datos personales sean tratados
              para estos Fines Adicionales, usted puede borrar La App y
              enviarnos un mensaje a
              <a href="mailto:privacy@trebelmusic.com"
                >privacy@trebelmusic.com</a
              >
              pidiendo que borremos sus datos personales permanentemente.
            </p>
            <h3>
              2. Datos Personales Que Usted Nos Provee
            </h3>
            <p>
              La Aplicación obtiene la información que usted provee cuándo
              descarga y se registra en La Aplicación. El proceso de registro es
              obligatorio para poder usar las características básicas de La
              Aplicación. Cuando usted se registra con nosotros y usa La
              Aplicación, generalmente provee:
            </p>
            <p>
              (a) su nombre, dirección de correo electrónico, edad, nombre de
              usuario, contraseña, y otra información de registro; (b)
              información relacionada a las transacciones, como por ejemplo
              cuando usted realiza compras, responde a las ofertas, o descarga
              y/o usa aplicaciones y contenido nuestro; (c) información que
              usted nos provee cuando nos contacta para recibir ayuda; y (d)
              información que usted provee en el sistema cuando hace uso de La
              Aplicación, como por ejemplo información de contacto y otra
              información relacionada a sus gustos y sus preferencias de
              contenido digital, sus amigos de las redes sociales, edad, género
              y otra información personal. Podremos usar esta información que
              usted ha provisto para contactarlo de vez en cuando con
              información importante, avisos requeridos, y promociones de
              marketing.
            </p>
            <h3>3. Datos Personales Que Recopilamos Automáticamente</h3>
            <p>
              Le informamos que tanto “La Aplicación” como la página de internet
              de TREBEL utiliza cookies, web beacons u otras tecnologías de
              rastreo, a través de las cuales es posible monitorear su
              comportamiento como usuario de internet, así como brindarle un
              mejor servicio y experiencia al usar “La Aplicación” y navegar en
              nuestra página. Los datos personales que recabamos a través de
              estas tecnologías, los utilizaremos para los fines señalados en la
              presente Política.
            </p>
            <p>
              Los datos personales que obtenemos de estas tecnologías de rastreo
              son los siguientes:
            </p>
            <ul>
              <li>Identificadores, nombre de usuario y contraseñas</li>
              <li>Idioma preferido por el usuario</li>
              <li>Región en la que se encuentra el usuario</li>
              <li>
                Fecha y hora del inicio y final de una sesión de un usuario
              </li>
              <li>Páginas web visitadas por un usuario</li>
              <li>Búsquedas realizadas por un usuario</li>
              <li>Publicidad revisada por un usuario</li>
              <li>Listas y hábitos de consumo en páginas de compras</li>
              <li>La ubicación del usuario</li>
              <li>
                Las preferencias musicales y hábitos de escuchar música del
                usuario
              </li>
              <li>
                Otra información proporcionada por el teléfono móvil del usuario
                y su navegador de web
              </li>
            </ul>
            <p>
              Adicionalmente, La Aplicación podrá recopilar alguna información
              automáticamente, incluyendo pero sin limitarse a el tipo de
              dispositivo que usted usa, el identificador único de su
              dispositivo, la dirección IP de su dispositivo, el sistema
              operativo de su dispositivo, el tipo de navegador que usa, e
              información sobre la forma como usa La Aplicación.
            </p>
            <p>
              La Aplicación tiene una función de filtros que usa herramientas de
              profundidad y mapeo facial provistos por la interfaz de
              programación de realidad aumentada. Los datos recopilados de las
              herramientas de profundidad y mapeo facial no se utilizan para
              publicidad o para otros propósitos de data mining y no se guardan
              permanentemente ni se comparten con terceros.
            </p>
            <h3>
              4. ¿La Aplicación recopila información exacta de la ubicación de
              su dispositivo?
            </h3>
            <p>
              Cuando usted visite La Aplicación, podremos usar tecnología GPS (u
              otra tecnología similar) para determinar su ubicación actual para
              poder determinar la ciudad en la que usted se encuentra y poder
              presentarle un mapa con publicidad relevante y lugares para que
              usted haga check-in. No compartiremos su ubicación actual con
              terceros para propósitos que no sean proveer anuncios más
              relevantes. Si usted no quiere que usemos su ubicación para los
              propósitos descritos, debe apagar los servicios de ubicación para
              La Aplicación a través de los ajustes de su cuenta y/o a través de
              los ajustes de su teléfono.
            </p>
            <h3>
              5. ¿Hay terceros que ven y/o tienen acceso a la información
              obtenida por La Aplicación?
            </h3>
            <p>
              Sí. Compartiremos su información con terceros sólo en las formas
              descritas en esta declaración de privacidad. Podremos revelar
              Datos Personales Que Usted Nos Provee, Datos Personales Que
              Recopilamos Automáticamente y datos recopilados para Finalidades
              Adicionales:
            </p>
            <ul>
              <li>
                Como lo requiera la ley, por ejemplo, para cumplir con una
                citación o con un proceso legal similar;
              </li>
              <li>
                Cuando creemos que en buena fe revelar los datos es necesario
                para proteger nuestros derechos, proteger su seguridad o la
                seguridad de otros, investigar fraude, o responder a una
                petición del gobierno;
              </li>
              <li>
                Con los proveedores de servicios de confianza que trabajan en
                nuestro nombre, que no tienen acceso independiente a la
                información que les revelamos, y que han convenido a adherirse a
                las políticas descritas en esta declaración de privacidad.
              </li>
              <li>
                Si M&M Media está involucrado en una fusión, adquisición, o
                venta de todos o parte de nuestros activos, usted será
                notificado por correo y/o un anuncio prominente en nuestra
                página web de cualquier cambio de propietario o de usos de esta
                información, al igual que sobre las opciones que usted podrá
                tener con respecto a su información.
              </li>
              <li>
                A anunciantes y redes publicitarias de terceros, así como
                empresas de análisis de datos como se describe en la siguiente
                sección.
              </li>
            </ul>
            <h3>6. Recolección automática de datos</h3>
            <p>
              Podremos trabajar con empresas de análisis de datos para ayudarnos
              a entender cómo se está usando La Aplicación, como por ejemplo la
              frecuencia y la duración de las sesiones. Trabajamos con
              anunciantes y redes publicitarias de terceros, quienes deben saber
              cómo usted interactúa con la publicidad provista en La Aplicación.
              Esto nos ayuda a mantener el costo de la aplicación gratis o bajo.
              Los anunciantes y las redes publicitarias de terceros usan alguna
              de la información recopilada en La Aplicación incluyendo, pero no
              limitándose al identificador único de su dispositivo móvil y su
              número de teléfono. Para proteger la anonimidad de esta
              información usamos tecnología de cifrado para poder asegurarnos
              que los terceros no puedan identificarlo a usted personalmente.
              Estos terceros también podrán obtener información anónima de otras
              aplicaciones que usted ha descargado en su dispositivo móvil, las
              páginas web que usted visita, la información no exacta de su
              ubicación (por ejemplo, su código postal), y otra información de
              ubicación no exacta para poder analizar y servir publicidad
              dirigida en La Aplicación y en otros lugares. También podremos
              compartir versiones cifradas de información que usted ha provisto
              para poder permitir que nuestros colaboradores añadan otra
              información disponible sobre usted para el análisis u otros usos
              relacionados a la publicidad. Usamos empresas de publicidad de
              terceros para servir anuncios cuando usted visita nuestro sitio
              web o usa La Aplicación de TREBEL MUSIC. Estas empresas podrán
              usar información agregada (sin incluir su nombre, dirección,
              dirección de correo, o teléfono) sobre sus visitas a este y otros
              sitios web para poder servir publicidad sobre bienes y servicios
              de su interés. Si usted quiere más información sobre nuestras
              prácticas o quiere saber sobre sus opciones para no permitir que
              estas compañías usen su información, visite: http://
              www.networkadvertising.org/managing/opt_out.asp
            </p>
            <h3>7. ¿Cuáles son mis derechos de exclusión voluntaria?</h3>
            <p>
              Usted puede detener la recolección de información por parte de La
              Aplicación fácilmente al desinstalar La Aplicación. Podrá usar el
              sistema estándar de desinstalación como sea provisto por su
              teléfono móvil, o a través de la tienda de aplicaciones móviles
              que usted utilice.
            </p>
            <h3>
              8. Política de conservación de datos y el manejo de su información
            </h3>
            <p>
              Usted tiene derecho a conocer qué datos personales tenemos de
              usted, para qué los utilizamos y las condiciones del uso que les
              damos (Acceso). Asimismo, es su derecho (i) solicitar la
              corrección de su información personal en caso de que esté
              desactualizada, sea inexacta o incompleta (Rectificación); (ii)
              que la eliminemos de nuestros registros o bases de datos cuando
              considere que la misma no está siendo utilizada adecuadamente
              (Cancelación); así como (iii) oponerse al uso de sus datos
              personales para fines específicos (Oposición). Estos derechos se
              conocen como derechos ARCO.
            </p>
            <p>
              Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
              presentar la solicitud respectiva a través del siguiente medio:
              envío de un correo electrónico a
              <a href="mailto:privacy@trebelmusic.com"
                >privacy@trebelmusic.com</a
              >.
            </p>
            <p>
              Conservaremos los Datos Personales Que Usted Nos Provee por el
              tiempo que usted use La Aplicación y por un tiempo razonable
              después del hecho. Conservaremos los Datos Personales Que
              Recopilamos Automáticamente por hasta 24 meses y luego los
              podremos conservar de manera agregada. Si usted quisiera que
              eliminemos los datos que usted nos ha provisto a través de La
              Aplicación, por favor contáctenos a
              <a href="mailto:privacy@trebelmusic.com"
                >privacy@trebelmusic.com</a
              >
              y le responderemos en un tiempo razonable. Por favor tenga en
              cuenta que alguna o toda la información que usted provee puede ser
              requerida para que La Aplicación funcione apropiadamente.
            </p>
            <h3>9. Transferencia a otros países</h3>
            <p>
              La Aplicación utiliza sus datos personales en otros países con
              objeto de realizar las actividades señaladas en esta Política. La
              Aplicación puede también subcontratar el tratamiento o compartir
              sus datos personales con terceros situados en países que no son su
              propio país. Por lo tanto, los datos personales pueden estar
              sujetos a leyes de privacidad que sean distintas de las de su
              país.
            </p>
            <p>
              Por ejemplo, es posible que los datos personales recopilados en
              México sean transferidos a terceros situados en un país distinto,
              y ser tratados por dichos terceros. En tales casos, La Aplicación
              garantizará que la transferencia de los datos personales sea
              realizada con arreglo a la legislación de privacidad vigente.
            </p>
            <h3>10. Menores de edad</h3>
            <p>
              No usamos la aplicación a sabiendas para recopilar datos o para
              mostrar publicidad a niños menores de 13 años. Si es usted el
              padre o la madre de un menor que no ha cumplido el Límite de Edad
              y se percata de que su hijo ha proporcionado datos personales sin
              su consentimiento, debe contactarnos a
              <a href="mailto:privacy@trebelmusic.com"
                >privacy@trebelmusic.com</a
              >. Suprimiremos estos datos en un tiempo razonable.
            </p>
            <h3>11. Seguridad</h3>
            <p>
              La privacidad y la seguridad de sus datos personales son de
              fundamental importancia para nosotros. Proveemos salvaguardas
              electrónicos y procesales para proteger la información que
              procesamos y que conservamos. Por ejemplo, limitamos el acceso a
              esta información a personal autorizado y contratistas que deben
              saber la información para operar, desarrollar o mejorar La
              Aplicación. Por favor, tenga en cuenta que a pesar de que hacemos
              todo lo posible para proveer una seguridad razonable para la
              información que procesamos y mantenemos, ningún sistema puede
              prevenir todo tipo de posibles brechas de seguridad.
            </p>
            <h3>12. Modificaciones a esta política</h3>
            <p>
              Es posible que de vez en cuando modifiquemos esta Política por
              cualquier razón. Le notificaremos de los cambios a esta política
              publicando una nueva política acá. Le recomendamos que consulte
              esta política de privacidad frecuentemente para que esté enterado
              de los cambios, ya que continuar usando el servicio se interpreta
              como una aprobación de los cambios.
            </p>
            <h3>13. Su consentimiento</h3>
            <p>
              Al usar La Aplicación, usted está consintiendo a que procesemos su
              información como se describió en esta Política de Privacidad en
              este momento y más adelante como se modifique por nosotros.
              “Procesar” significa usar cookies en un computador / un
              dispositivo de mano, o usar o tocar su información de cualquier
              manera, incluyendo pero no limitándose recopilar, conservar,
              eliminar, usar, combinar y revelar información.
            </p>
            <h3>14. Cómo contactarnos</h3>
            <p>
              Si tiene preguntas sobre la privacidad al usar La Aplicación o
              tiene preguntas sobre las prácticas descritas, por favor
              contáctenos por correo electrónico a
              <a href="mailto:privacy@trebelmusic.com"
                >privacy@trebelmusic.com</a
              >.
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t("privacyPolicy"),
      titleTemplate: "TREBEL MUSIC: %s",
      htmlAttrs: {
        lang: this.$t("lang")
      },
      meta: this.generateMeta({
        title: `TREBEL MUSIC: ${this.$t("privacyPolicy")}`,
        description: this.$t("meta.home.description"),
        url: this.generateUrl(this.locale)
      })
    };
  },
  computed: {
    language() {
      return this.$route.query.country === "MX" ? "mx" : "us";
    },
    locale() {
      return this.$store.getters.locale;
    }
  }
};
</script>
